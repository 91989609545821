<template>
  <v-autocomplete
    v-model="select"
    :items="entries"
    :loading="isLoading"
    :search-input.sync="search"
    flat
    item-text="englishname"
    item-value="personid"
    label="Author"
    cache-items
    placeholder="Start typing to Search"
    append-outer-icon="mdi-plus-circle"
    return-object
    @click:append-outer="addNewPerson()"
  ></v-autocomplete>
</template>
<script>
import DocAdmin from '@/store/data'
import configs from '@/configs'

export default {
  name: 'AuthorLookupField',
  props: {
    selected: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    entries: [],
    isLoading: false,
    search: '',
    count: null,
    connectionType: configs.connectionProperties.types,
    select: null
  }),
  watch: {
    select(value) {
      this.$emit('input', value ? value : null)
    },
    selected(value) {
      this.setSelected(value)
    },
    search(query) {
      if (query && (!this.select || this.select.englishname !== query)) {
        this.querySelections(query)
      }
    }
  },
  created () {
    this.querySelections(this.selected ? this.selected.englishname : '')
  },
  methods: {
    setSelected(personObject) {
      if (personObject && personObject.englishname) {
        this.select = personObject
        this.querySelections(personObject.englishname)

        return
      }
      if (!personObject) {
        this.select = null
      } else if (!this.select || this.select.personid !== personObject.personid) {
        const item = this.getItemById(personObject)

        if (item) {
          this.select = item
        } else {
          this.querySelections('')
        }
      }
    },
    getItemById(personObject) {
      return this.entries.find((item) => item.personid === personObject.personid)
    },
    querySelections(q) {
      this.isLoading = true
      DocAdmin.persons.list({ params: { search: q } }).then((res) => {
        const { count, results } = res.data

        this.count = count
        this.entries = results
      }).catch((err) => {
        console.error(`AuthorLookupField.search.error =${err}`)
      }).finally(() => (this.isLoading = false))
    },
    addNewPerson() {
      alert('yet to be implemented')
    }
  }
}
</script>