<template>
  <v-row v-if="allowEdit(document.documenttypeid.name)" no-gutters>
    <v-col 
      cols="8"
      sm="12"
      md="8"
      lg="9"
      xl="9"
    >
      <v-alert
        v-if="error.show"
        icon="mdi-alert-circle"
        dismissible
        text
        type="error"
      >
        {{ error.message }}
      </v-alert>

      <v-expansion-panels v-model="panels" tile flat multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-col cols="8">
                <span><strong>Document Item: </strong>{{ documentItem.englishtitle }}</span>
              </v-col>
              <v-col v-if="sections.documentItem.pendingChanges" cols="4" class="text-right pr-2">
                <span class="orange--text caption">Unsaved Changes</span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ImageManager
              v-if="isImageEnabled && (documentItem && Object.keys(documentItem).length)"
              :id="documentItem.documentitemid"
              :connection-type-id="connectionType.documentItems"
              :allow-upload="true"
            />
            <DocumentItemForm 
              v-if="documentItem && Object.keys(documentItem).length" 
              :document-item="documentItem" 
              :save="saveDocumentItem"
              @pendingChanges="changes('documentItem', $event)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="renderField(documentItem.documentitemtypeid, 'author')">
          <v-expansion-panel-header>
            <v-row no-gutters>
              <v-col cols="8">
                <span v-if="author">
                  <strong>Author: </strong>
                  <router-link :to="{ name: 'person_home', params: { personid: author.personid }}">
                    {{ author.englishname }}
                  </router-link>
                </span>
                <span v-else><strong>Add Author</strong></span>
              </v-col>
              <v-col v-if="sections.author.pendingChanges" cols="4" class="text-right pr-2">
                <span class="orange--text caption">Unsaved Changes</span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <AuthorForm 
              :item-author="author" 
              :save="saveAuthor"
              @pendingChanges="changes('author', $event)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="isRenderComponent('allowedTermTypes')">
          <v-expansion-panel-header>
            <div v-if="Object.keys(documentItem.terms).length" class="d-flex">
              <div class="mr-1">
                <strong>Terms: </strong>
              </div>
              <div>
                <TermAssigned :data="documentItem" />
              </div>
            </div>
            <span v-else>
              <strong>Add Terms</strong>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="space-around" no-gutters>
              <v-col v-for="termType in isRenderComponent('allowedTermTypes')" :key="`att-${termType}`">
                <TermsField 
                  :term-type="termType" 
                  :source-id="documentItem.documentitemid" 
                  :source-connection-type-id="connectionType.documentItems"
                />
              </v-col>
            </v-row>
            <p class="text-caption blue--text">Note: Changes are auto saved.</p>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="isRenderComponent('enableItemAudio')">
          <v-expansion-panel-header>

            <v-row no-gutters>
              <v-col cols="8">
                <span v-if="'audio' in documentItem.meta">
                  <strong>Audio ({{ documentItem.meta.audio.value.length }})</strong>
                </span>
                <span v-else>
                  <strong>Add Audio</strong>
                </span>
              </v-col>
              <v-col v-if="sections.audio.pendingChanges" cols="4" class="text-right pr-2">
                <span class="orange--text caption">Unsaved Changes</span>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <AudioForm 
              :data="documentItem"
              @pendingChanges="changes('audio', $event)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <strong>Translations ({{ documentItemTranslations ? documentItemTranslations.length: 0 }})</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <DocumentItemTranslationListing view="listing" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-col>
    <v-col
      cols="4"
      sm="12"
      md="4"
      lg="3"
      xl="3"
      class="pl-2"
    >
      <v-card tile>
        <v-card-title>Information</v-card-title>
        <debug v-if="debug">
          <template slot="info" class="text-xs">
            author={{ author }}<br/>
            authorConnection={{ authorConnection }}<br/>
            sections={{ sections }}
          </template>
        </debug>
      </v-card>
    </v-col>
  </v-row>
  <v-alert v-else prominent type="error">
    Access is denied, you do not have the required groups to access this, your group: {{ groups }}
  </v-alert>
</template>
<script>
// document-item-manage
import { mapGetters, mapState } from 'vuex'
import eBus from '@/eventbus'
import configs from '@/configs'
import DocumentItemTranslationListing from '@/components/documentItemTranslations/DocumentItemTranslationListing'
import DocumentItemForm from '@/components/documentItems/forms/DocumentItemForm'
import AuthorForm from '@/components/forms/AuthorForm'
import TermsField from '@/components/terms/TermsField'
import Debug from '@/components/common/Debug'
import DocAdmin from '@/store/data'
import Vue from 'vue'
import TermAssigned from '@/components/terms/TermAssigned'
import ImageManager from '@/components/ImageManager'
import AudioForm from '@/components/meta/Audio/AudioForm'
import types from '@/helpers/types'

export default {
  name: 'DocumentItemEditorPage',
  components: {
    DocumentItemTranslationListing,
    DocumentItemForm,
    AuthorForm,
    TermsField,
    Debug,
    TermAssigned,
    ImageManager,
    AudioForm
  },
  data: () => ({
    panels: [0],
    loading: true,
    connectionType: configs.connectionProperties.types,
    error: {
      show: false,
      message: null
    },
    author: null,
    authorConnection: null,
    sections: {
      author: {
        pendingChanges: false
      },
      documentItem: {
        pendingChanges: false
      },
      terms: {
        pendingChanges: false
      },
      audio: {
        pendingChanges: false
      }
    }
  }),
  computed: {
    ...mapGetters('documents', ['renderComponent', 'renderField', 'getDocumentItemDataStructureById']),
    ...mapGetters('documentItems', ['getDocumentItemTranslations']),
    ...mapGetters('account', ['allowEdit']),
    ...mapState('documentItems', ['documentItem']),
    ...mapState('documents', ['document']),
    ...mapState('account', ['groups']),
    ...mapState('app', ['debug']),
    documentItemTypeName() {
      return types.getDocumentItemTypeNameById(this.documentItem.documentitemtypeid)
    },
    documentItemTranslations() {
      if (!this.documentItem) { return null }

      return this.getDocumentItemTranslations(this.documentItem.documentitemid)
    },
    pendingChanges() {
      let pendingChanges = false

      Object.keys(this.sections).forEach((a) => {
        if (this.sections[a].pendingChanges) {
          pendingChanges = true
        }
      })

      return pendingChanges
    },
    isImageEnabled() {
      const ds = this.getDocumentItemDataStructureById(this.documentItem.documentitemtypeid)

      if (!ds) { return false }
      if (!('enableItemImage' in ds)) { return false }

      return ds.enableItemImage
    }
  },
  created() {
    this.loadData()
    this.getAuthorConnection()
  },
  beforeRouteLeave(to, from, next) {
    if (this.pendingChanges) {
      const continute = confirm('You have unsaved changes are you sure you want to leave? ditpage')

      if (continute) { 
        return next() 
      }
      
      return next(false)
    }
    
    return next()
  },
  methods: {
    emitUpdateDocumentItem (field, value) {
      console.log(`emitUpdateDocumentItem, field=${field}, value=${value}`)
      eBus.$emit('update_document_item', { field, value })
    },
    async getAuthorConnection() {
      console.log('DocumentItemEditorPage.getAuthorConnection')

      const results = await this.getConnections({
        destination_connectiontypeid: this.connectionType.persons,
        connectionpurposeid: configs.connectionProperties.purpose.author
      })

      if (results.data.results.length > 0) {
        const personId = results.data.results[0].destinationid
        const personObject = await DocAdmin.persons.get(personId)

        this.author = personObject.data
        this.authorConnection = results.data.results[0]
      }
    },
    async getConnections(params) {
      const documentItemId = this.$route.query.documentitemid ? this.$route.query.documentitemid : this.$route.params.documentitemid

      params.sourceid = documentItemId
      params.source_connectiontypeid = this.connectionType.documentItems

      return DocAdmin.connections.list({ params: params } )
    },
    async updateConnections(id, params) {
      const documentItemId = this.$route.query.documentitemid ? this.$route.query.documentitemid : this.$route.params.documentitemid

      params.sourceid = documentItemId
      params.source_connectiontypeid = this.connectionType.documentItems

      return DocAdmin.connections.update(id, params)
    },
    async addConnection(params) {
      const documentItemId = this.$route.query.documentitemid ? this.$route.query.documentitemid : this.$route.params.documentitemid
      
      params.sourceid = documentItemId
      params.source_connectiontypeid = this.connectionType.documentItems

      return DocAdmin.connections.create(params)
    },
    async removeConnection(id) {
      await DocAdmin.connections.remove(id)
    },
    async removeMeta(id) {
      await DocAdmin.metadata.remove(id)
    },
    async saveDocumentItem(documentItem) {
      
      if ('documentitemtypeid' in documentItem) {
        documentItem.documentitemtypeid = documentItem.documentitemtypeid.documentitemtypeid
      }
      
      const documentItemResponse = await this.$store.dispatch('documentItems/updateDocumentItem', { 
        id: documentItem.documentitemid, payload: documentItem
      })
      
      this.changes('documentItem', false)
      this.$store.dispatch('app/setSnackBar', 'Document Item updated.')
      
      this.$router.push({ 
        name: 'documentitem', 
        params: {
          documentitemid: documentItemResponse.documentitemid
        }
      })
    },
    async saveAuthor(person) {

      this.author = person

      if (!Object.keys(person).length && (this.authorConnection && Object.keys(this.authorConnection).length)) {
        console.log(`DocumentItemEditorPage.saveAuthor.removeConnection=${this.authorConnection.sourceid}`)
        this.removeConnection(this.authorConnection.sourceid)
        this.emitUpdateDocumentItem('englishtitle', '')

        return
      }
      
      if (person && Object.keys(person).length) {

        if (this.authorConnection && Object.keys(this.authorConnection).length) {
          console.log(`DocumentItemEditorPage.saveAuthor.updateConnections=${this.authorConnection.sourceid}`)
          await this.updateConnections(this.authorConnection.sourceid, {
            destinationid: person.personid,
            destination_connectiontypeid: this.connectionType.persons,
            connectionpurposeid: configs.connectionProperties.purpose.author
          })
          this.emitUpdateDocumentItem('englishtitle', `Commentary By ${person.englishname}` )

          return
        }

        if (!this.authorConnection && person) {
          console.log(`DocumentItemEditorPage.saveAuthor.addConnection=${person.personid}`)
          this.addConnection({
            destinationid: person.personid,
            destination_connectiontypeid: this.connectionType.persons,
            connectionpurposeid: configs.connectionProperties.purpose.author
          })
          this.emitUpdateDocumentItem('englishtitle', `Commentary By ${person.englishname}` )
        }
      }
      
    },
    async loadData() {
      const documentItemId = this.$route.query.documentitemid ? this.$route.query.documentitemid : this.$route.params.documentitemid

      if (!this.documentItem || (Number(this.documentItem.documentitemid) !== Number(documentItemId))) {
        console.log(`DocumentItemEditorPage.loading documentitem with documentitemid=${documentItemId}`)
        await this.$store.dispatch('documentItems/getDocumentItem', documentItemId)
      }
      this.loading = false
    },
    isRenderComponent (componentName) {
      return this.renderComponent(this.documentItem.documentitemtypeid, componentName)
    },
    changes (section, changes) {
      console.log(`section=${section}, changes=${changes}`)
      Vue.set(this.sections[section], 'pendingChanges', changes)
    }
  }
}
</script>