<template>
  <div>
    <v-card :loading="loading" tile class="mx-auto">
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="2" indeterminate></v-progress-linear>
      </template>
      <!-- <ImageManager
        :id="documentItem.documentitemid"
        :connection-type-id="connectionType.definition"
      /> -->
      <v-card-title v-if="definitionTitle && definitionId" class="font-weight-medium text-capitalize">
        <router-link 
          :to="{ name: 'definition_home', params: { definitionid: definitionId }}"
        >
          {{ definitionTitle }}
        </router-link>
      
      </v-card-title>

      <v-card-text v-for="d in definitionExplanationTranslations" :key="d.definitiontranslationid" class="text-justify">
        <div v-html="d.definition"></div>
        <div v-if="listOfSynonyms.length" class="text-subtitle-1">
          {{ listOfSynonyms }}
        </div>
        <p class="overline font-weight-thin" small>DEFINITION</p>
      </v-card-text>
      
    </v-card>
    
    <v-divider class="mx-4"></v-divider>
  </div>
</template>

<script>
import configs from '@/configs'
import DocAdmin from '@/store/data'

export default {
  name: 'Definition',
  components: {},
  props: {
    explanationId: {
      type: Number,
      default: null
    },
    definitionTitle: {
      type: String,
      default: null
    },
    languageId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    loading: true,
    listOfSynonyms: [],
    definitionExplanationTranslations: null,
    definitionId: null
  }),
  computed: { },
  watch: { },
  mounted() {
    this.getDefinitionExplanation()
  },
  methods: {
    async getDefinitionExplanation () {
      const urlParams = { 
        params: { 
          definitionexplanationid: this.explanationId, 
          languageid: this.languageId
        } 
      }
      const definitionExplanationTranslations = await DocAdmin.definitions.explanationTranslations.list(urlParams)

      this.definitionExplanationTranslations = definitionExplanationTranslations.data.results

      const explanantionResponse = await DocAdmin.definitions.explanations.get(this.definitionExplanationTranslations[0].definitionexplanationid)

      this.definitionId = explanantionResponse.data.definitionid
      this.loading = false
    },
    getSynonyms (definitionId) {
      const params = { 
        params: {
          definitionid: definitionId
        }
      }
      
      DocAdmin.definitions.synonyms.list(params).then((response) => {
        response.data.results.forEach((key, value) => {
          this.listOfSynonyms.push(value.synonym)
        })
      })
    }
  }
}
</script>