<template>
  <v-progress-linear v-if="loading" :size="20" color="primary" indeterminate/>

  <div v-else>
    <div v-if="documentItem && Object.keys(documentItem).length && !isFormAdd">
      
      <DocumentItemHeading :key="`dih-${parseInt(documentItem.documentitemid)}`" :document-item="documentItem"/>

      <DocumentItemsNavigation 
        v-if="showNavigation"
        :key="`din-${parseInt(documentItem.documentitemid)}`"
        :document-id="parseInt(documentItem.documentid)" 
        :document-item-type-id="parseInt(documentItem.documentitemtypeid)"
        :parent-document-item-id="parseInt(documentItem.documentitemid)"
        class="mb-2"
      />
      <router-view />
    </div>

    <v-alert
      v-if="!documentItem && !isFormAdd"
      border="bottom"
      color="pink darken-1"
      dark
      flat
      tile
    >
      DocumentItem with Id={{ $route.params.documentitemid }} not found.
    </v-alert>
    <router-view v-if="isFormAdd" />
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import DocumentItemsNavigation from '@/components/documentItems/DocumentItemsNavigation'
import DocumentItemHeading from '@/components/documentItems/DocumentItemHeading'
import configs from '@/configs'

export default {
  name: 'DocumentItemLayout',
  components: {
    DocumentItemsNavigation,
    DocumentItemHeading
  },
  data: () => ({
    loading: true,
    connectionType: configs.connectionProperties.types,
    definitionId: null,
    definitionTitle: null,
    personId: null,
    phraseId: null,
    referenceLinkId: null
  }),
  computed: {
    ...mapState('documents', ['dataStructure']),
    ...mapState('documentItems', ['documentItem', 'isLeafNode']),
    ...mapActions('documentItems', ['getDocumentItem']),
    ...mapGetters('documents', ['getDocumentDataStructure', 'getDocumentItemDataStructureById']),
    ...mapGetters('documentItems', ['isMoreInfoPaneOpen']),
    showNavigation() {
      return ((this.documentItem && this.documentItem.documentid && !this.isLeafNode) && !String(this.$route.name).includes('edit'))
    },
    routeName() {
      return this.$route.name
    },
    isFormAdd() {
      if (this.routeName === 'documentitem_add') {
        return true
      }

      return false
    }
  },
  mounted () {
    if (this.isFormAdd) { 
      this.loading = false

      return 
    }
    
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true

      if (!this.dataStructure) { 
        console.error('DocumentItemLayout.loadData no datastructure not loading data')

        return
      }

      try {
        // must load this to determine leafnodey ness
        await this.$store.dispatch('documentItems/getDocumentItem', this.$route.params.documentitemid)

        if (this.isLeafNode) {
          console.log(`DocumentItemLayout.loading(documentItems/getDocumentItemWithChildren, ${this.$route.params.documentitemid})`)
          await this.$store.dispatch('documentItems/getDocumentItemWithChildren', this.$route.params.documentitemid)
        } else {
          console.log(`DocumentItemLayout.loading(documentItems/getDocumentItemTranslations, ${this.$route.params.documentitemid})`)
          await this.$store.dispatch('documentItems/getDocumentItemTranslations', this.$route.params.documentitemid)
        }
      } catch (err) {
        console.error(`DocumentItemLayout.Error in loading data, error=${err}`)
      }

      this.loading = false
    }
  }
}
</script>