<template>
  <div>
    <v-row no-gutters>
      <v-col
        v-for="(translation, index) of sortedTranslations"
        :key="`documentitemtranslation-${index}`"
        :cols="translationColumns"
        class="pl-1"
      >
        <DocumentItemTranslation 
          :item="item" 
          :translation="translation" 
          :index="index" 
          :translation-id="translation.id"
        />
      </v-col>
    </v-row>

    <!-- <v-row v-if="shlokView" no-gutters>
      <div
        v-for="(translation, index) of sortedTranslations"
        :key="`documentitemtranslation-${index}`"
        class="mx-auto text-center"
      >
        <DocumentItemTranslation :item="item" :translation="translation" :index="index" :translation-id="translation.id"/>
      </div>
    </v-row> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DocumentItemTranslation from '@/components/documentItemTranslations/DocumentItemTranslation'
import DocAdmin from '@/store/data'

export default {
  name: 'DocumentItemTranslations',
  components: {
    DocumentItemTranslation
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    translations: {
      type: Array,
      default: null
    },
    view: {
      type: String,
      default: 'default'
    },
    layoutInRows: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    groupItems: []
  }),
  methods: {
    loadGroupedItems() {
      this.groupedData.forEach(async (group) => {
        await DocAdmin.documentItemTranslation.list({ params: { 
          documentitemid: group.destination_documentitemid,
          languageid: group.languageid
        } }).then((r) => {
          const [data] = r.data.results
          
          data.number = group.number
          this.groupItems.push(data)
        })
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.loadGroupedItems()
    })
  },
  computed: {
    ...mapState('documents', ['document']),
    ...mapState('app', ['readingLanguageOrder']),
    translationColumns() {
      if (!this.layoutInRows) {
        return 12
      }
      
      const numberOfTranslations = this.translations.length
      const columns = Math.round(12 / numberOfTranslations)

      return columns
    },
    groupedData () {
      if (!this.item.grouping_data) { return [] }
      return this.item.grouping_data['separateParagraphs']
    },
    groupItemsSorted () {
      return this.groupItems.sort((a, b) => a.number - b.number);
    },
    sortedTranslations() {
      if (this.translations.length < 0) return []

      return this.translations.sort((a, b) => {
        return this.readingLanguageOrder.indexOf(String(a.languageid)) - this.readingLanguageOrder.indexOf(String(b.languageid))
      })

    },
    defaultView() {
      return this.item && this.view === 'default'
    },
    shlokView() {
      return this.item && this.view === 'shlok'
    }
  }
}
</script>