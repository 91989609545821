<template>
  <v-card flat tile class="mb-2">
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="8">
        <AuthorLookupField 
          :selected="authorLocal" 
          @input="updateLocal('authorLocal', $event)"
        />
      </v-col>
      <v-col cols="6" md="4">
        <v-btn @click="saveLocal" :disabled="!changed">{{ buttonText }}</v-btn>
      </v-col>
    </v-row>
    
    <debug v-if="debug">
      <template slot="sub-heading">AuthorForm</template>
      <template slot="info" class="text-xs">
        authorLocal={{ authorLocal }}<br/><br/>
        itemAuthor={{ itemAuthor }}<br/><br/>
        changed={{ changed }}
      </template>
    </debug>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import AuthorLookupField from '@/components/shared/AuthorLookupField'
import Debug from '@/components/common/Debug'
import Vue from 'vue'

import _ from 'lodash'

export default {
  name: 'AuthorForm',
  components: {
    AuthorLookupField,
    Debug
  },
  props: {
    itemAuthor: {
      type: Object
    },
    save: {
      type: Function,
      required: true
    },
    buttonText: {
      type: String,
      default: 'Save'
    }
  },
  data() {
    return {
      authorLocal: {},
      changed: false
    }
  },
  mounted(){
    if (this.itemAuthor) {
      this.authorLocal = _.cloneDeep(this.itemAuthor)
    }
  },
  computed: {
    ...mapState('app', ['debug'])
  },
  methods: {
    updateLocal (field, value) {
      Vue.set(this, 'authorLocal', value)
      if (JSON.stringify(this.itemAuthor) !== JSON.stringify(value)) {
        this.changes(true)
        return
      } 
      this.changes(false)
    },
    changes (changes) {
      this.changed = changes
      this.$emit('pendingChanges', changes)
    },
    async saveLocal() {
      try {
        const a = this.authorLocal ? this.authorLocal : {}
        await this.save(a)
        this.changes(false)
      } catch (err) {
        console.log(`AuthorForm.Error saving error=${err}`)
      }
    }
  }
}
</script>