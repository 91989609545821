<template>
  <v-autocomplete
    v-model="select"
    :items="entries"
    :search-input.sync="searchInput"
    prepend-inner-icon="mdi-database-search-outline"
    flat
    :item-text="itemText"
    item-value="url"
    label="Lookup track from bhaktisound"
    placeholder="Start typing to Search"
    return-object
    dense
  ></v-autocomplete>
</template>
<script>
import ExternalApi from '@/store/data'
import mconfig from '@/configs/metaConfig'

export default {
  name: 'BhaktiSoundAudioLookup',
  data: () => ({
    entries: [],
    isLoading: false,
    searchInput: null,
    count: null,
    select: null
  }),
  watch: {
    select(value) {
      if (value) {
        this.$emit('input', {
          streamUrl: `${mconfig.audio.bhaktisoundBaseUrl}/${value.url}`,
          title: this.itemText(value)
        })

        return
      }
      this.$emit('input', null)
    },
    searchInput(q) {
      if (q) {
        this.querySelections(q)
      }
    }
  },
  created () {
    this.querySelections(this.selected ? this.selected.title : '')
  },
  methods: {
    itemText(i) {
      const artist = i && i.artists.length > 0 ? i.artists[0].name : ''

      return `${i.name} - ${artist}`
    },
    querySelections(q) {
      this.isLoading = true
      ExternalApi.bhaktisoundSearch.list({ params: {
        limit: 20,
        types: "track",
        query: q
      }}).then((res) => {
        const { count, results } = res.data
        this.count = count
        this.entries = results.tracks
      }).catch((err) => {
        console.error(`BhaktisoundAudioLookup.search.error =${err}`)
      }).finally(() => (this.isLoading = false))
    }    
  }
}
</script>