<template>
  <div v-if="data.length > 1" class="ma-0 pa-0 d-flex">
    <div class="mr-1">
      <AudioPlayer v-if="selectedFile" :file="selectedFile" color="success" :key="selectedFile"/>
    </div>
    <div>
      <v-menu offset-y :close-on-click="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="blue" small outlined v-bind="attrs" v-on="on">
            <v-icon small>mdi-playlist-music-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-subheader>Tracks</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item v-for="(a, i) in data" :key="i">
              <v-list-item-content>
                <v-list-item-title @click="selectedFile = a.streamUrl" v-text="a.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

        </v-list>
      </v-menu>
    </div>
  </div>
  <AudioPlayer v-else :file="data[0].streamUrl" color="success" />
</template>
<script>
import AudioPlayer from '@/components/meta/Audio/AudioPlayer'

export default {
  name: 'Audio',
  components: {
    AudioPlayer
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedFile: null
    }
  }
}
</script>