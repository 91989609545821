<template>
	<div>
		<v-switch
			v-model="editing"
			label="Enable live editing"
			hint="Enabling partial live editing"
			persistent-hint
			:dense="compact"
		></v-switch>
		<span v-if="$store.state.app.debug">$store.state.app.editing={{ $store.state.app.editing }}</span>
  </div>
</template>
<script>
export default {
	name: 'LiveEditing',
	props: {
		compact: {
			type: Boolean,
			default: false
		}
	},
	computed: {
    editing: {
      get () { return this.$store.state.app.editing },
      set (v) { this.$store.commit('app/toggleEditing') }
    }
  }
}
</script>