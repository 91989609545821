<template>

  <v-card tile flat>
    <v-alert
      v-if="error.show"
      icon="mdi-alert-circle"
      dismissible
      text
      type="error"
    >
      {{ error.message }}
    </v-alert>

    <v-card-text>
      <v-card-actions>
        <v-btn
          fab
          dark
          x-small
          color="blue" 
          @click.prevent="addItem"
        >
          <v-icon dark>
            mdi-plus-circle-outline
          </v-icon>
        </v-btn>
        <v-btn
          v-if="changed"
          fab
          dark
          x-small
          color="orange"
          @click.prevent="undo"
        >
          <v-icon dark>
            mdi-undo-variant
          </v-icon>
        </v-btn>
        <v-btn
          fab
          dark
          x-small
          color="green"
          type="submit"
          @click.prevent="save"
        >
          <v-icon dark>
            mdi-content-save-outline
          </v-icon>
        </v-btn>
      </v-card-actions>
      <ValidationObserver ref="observer">
        <form v-for="(audioItemLocal, index) in audioDataLocal" :key="`audio_form_${index}`" @submit.prevent="save">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              dark
              fab
              x-small
              @click.prevent="deleteItem(index)"
            >
              <v-icon>mdi-minus-circle-outline</v-icon>
            </v-btn>
          </v-card-actions>
          <div class="d-flex">
            <div class="mr-1">
              <ValidationProvider v-slot="{ errors }" :key="`audio_form_type_${index}`" name="type" rules="required">
                <v-autocomplete
                  :key="`audio_form_type_al_${index}`"
                  v-model="audioItemLocal.type"
                  :items="audioTypes"
                  flat
                  label="Type"
                  data-vv-name="type"
                  :error-messages="errors"
                  dense
                />
              </ValidationProvider>
            </div>
            <div>
              <ValidationProvider v-slot="{ errors }" :key="`audio_form_source_${index}`" name="source" rules="required">
                <v-autocomplete
                  :key="`audio_form_source_al_${index}`"
                  v-model="audioItemLocal.source"
                  :items="audioSources"
                  flat
                  label="Source"
                  data-vv-name="source"
                  :error-messages="errors"
                  dense
                />
              </ValidationProvider>
            </div>
            <div class="ml-1">
              <AudioPlayer v-if="audioItemLocal.streamUrl" :key="audioItemLocal.streamUrl" :file="audioItemLocal.streamUrl" color="success"/>
            </div>
          </div>
          <BhaktiSoundAudioLookup 
            v-if="audioItemLocal.type === 'kirtan' && audioItemLocal.source === 'bhaktisound'"
            :key="`audio_form_source_bal_${index}`"
            @input="updateLocal($event, index)"
          />
          <v-text-field 
            v-if="audioItemLocal.source === 'file'"
            :key="`audio_form_file_${index}`"
            flat
            label="File Location"
            dense
          />
          <ValidationProvider v-slot="{ errors }" :key="`audio_form_title_${index}`" name="title" :rules="titleRequired ? 'required' : []">
            <v-text-field 
              v-model="audioItemLocal.title" 
              label="Title"
              data-vv-name="title"
              :error-messages="errors"
              dense
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" :key="`audio_form_streamurl_${index}`" name="streamurl">
            <v-text-field 
              v-model="audioItemLocal.streamUrl" 
              label="Stream Url"
              data-vv-name="streamurl"
              :error-messages="errors"
              dense
            />
          </ValidationProvider>
        </form>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>
<script>
import DocAdmin from '@/store/data'
import configs from '@/configs'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, max, regex } from 'vee-validate/dist/rules'
import BhaktiSoundAudioLookup from './BhaktiSoundAudioLookup'
import AudioPlayer from './AudioPlayer.vue'
import _ from 'lodash'
import metaConfig from '@/configs/metaConfig'

extend('required', {
  ...required,
  message: '{_field_} can not be empty'
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})

extend('regex', {
  ...regex,
  message: 'Phone number must be valid'
})

const AudioItem = {
  source: null,
  type: null,
  streamUrl: null,
  title: null,
  valid: false
}

export default {
  name: 'AudioForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    BhaktiSoundAudioLookup,
    AudioPlayer
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      connectionType: configs.connectionProperties.types,
      audioDataLocal: [],
      changed: false,
      error: {
        show: false,
        message: ''
      },
      saving: false
    }
  },
  computed: {
    isNewRecord() {
      if (!('audio' in this.data.meta)) {
        return true
      }

      return false
    },
    titleRequired() {
      // eslint-disable-next-line no-constant-condition
      return true ? this.data.type !== 'recital' : false
    },
    audioTypes() {
      return metaConfig.audio.types
    },
    audioSources() {
      return metaConfig.audio.sources
    },
    isLastItem() {
      if (this.audioDataLocal && !this.isNewRecord) {
        return true
      }
      
      return false
    }
  },
  watch: {
    audioDataLocal: {
      deep: true,
      handler(a) {
        if (this.isNewRecord) {
          this.changes(true, 'watch-guard-is-new-record')

          return
        }

        if (JSON.stringify(this.data.meta.audio.value) !== JSON.stringify(a)) {
          this.changes(true, 'watch-guard-difference-in-value')

          return
        }

        this.changes(false, 'watch-guard-change-in-value')
      }
    }
  },
  created() {
    if (!this.isNewRecord) {
      this.audioDataLocal = JSON.parse(JSON.stringify(this.data.meta.audio.value))

      return
    }

    this.audioDataLocal.push(AudioItem)
  },
  methods: {
    deleteItem(index) {
      this.audioDataLocal.splice(index, 1)
    },
    addItem() {
      this.audioDataLocal.unshift(JSON.parse(JSON.stringify(AudioItem)))
    },
    undo() {
      if ('value' in this.data.meta.audio) {
        this.audioDataLocal = JSON.parse(JSON.stringify(this.data.meta.audio.value))

        return
      }
     
      this.audioDataLocal = []
    },
    async saveAsNew() {

      if (!('documentitemid' in this.data)) {
        console.error('Not supportted for this type yet. documentitemid')
        this.saving = false

        return
      }

      console.log(`AudioForm.saveAsNew with following data=${JSON.stringify(this.audioDataLocal)}`)

      try {
        await DocAdmin.metadata.create({
          key: 'audio',
          origin_connectiontypeid: this.connectionType.documentItems,
          originid: this.data.documentitemid,
          value: JSON.stringify(this.audioDataLocal)
        })
        this.$store.dispatch('app/setSnackBar', `(${this.audioDataLocal.length}) Audio tracks saved.`)
        this.changes(false, 'saveasnew')
        this.error.show = false
      } catch (err) {
        this.error.show = true
        this.error.message = `Error removing ${err}`
      }
    },
    async update() {
      console.log(`AudioForm.update with following data=${JSON.stringify(this.audioDataLocal)}`)

      try {
        await DocAdmin.metadata.update(this.data.meta.audio.metadataid, {
          key: 'audio',
          origin_connectiontypeid: this.connectionType.documentItems,
          originid: this.data.documentitemid,
          value: JSON.stringify(this.audioDataLocal)
        })
        this.$store.dispatch('app/setSnackBar', 'Audio track(s) updated.')
        this.changes(false, 'update')
        this.error.show = false
      } catch (err) {
        this.error.show = true
        this.error.message = `Error removing ${err}`
      }
    },
    async save() {     
      const isValid = await this.$refs.observer.validate()
      
      if (!isValid) {
        console.log(`AudioForm. form is not valid=${isValid}`)
        this.error.show = true
        this.error.message = 'Form invalid please ensure all fields are correct.'
        
        return
      }

      // if its there are no items in array remove whole meta.
      if (this.audioDataLocal.length === 0 && Object.keys(this.data.meta).length > 0) {
        this.removeAll()
        
        return
      }

      this.saving = true

      if (this.audioDataLocal.length) {
        if (this.isNewRecord) {
          this.saveAsNew()
          this.saving = false

          return
        }
        this.update()
        this.saving = false
      } else {
        this.error.show = true
        this.error.message = 'No action to take, what would you like to do?'
      }
    },
    async removeAll() {
      const id = this.data.meta.audio.metadataid
      const proceed = confirm('This will remove all audio are you sure?')
      
      if (!proceed) { return }

      try {
        await DocAdmin.metadata.remove(id)
        this.$store.dispatch('app/setSnackBar', `Audio removed for metaId=${id} and documentItemId=${this.data.documentitemid}.`)
        this.audioDataLocal = []
        this.changes(false, 'removeall')
      } catch (err) {
        this.error = true
        this.errorMessage = `Error removing ${err}`
      }
    },
    changes (changes, source) {
      // console.log(`Detected change=${changes} in audio form here=${source}`)
      this.$emit('pendingChanges', changes)
      
      this.changed = changes
    },
    updateLocal(item, index) {
      if (item.source !== 'file') {
        this.audioDataLocal[index].title = item.title
        this.audioDataLocal[index].streamUrl = item.streamUrl
      }
    }
  }
}
</script>