<template>
  <v-card class="mb-2 pl-2 pr-2" tile flat>
      <div class="d-flex pt-2">
        <Breadcrumb :document-item="documentItem"/>
        <v-spacer></v-spacer>
        <Audio v-if="hasAudio" :data="documentItem.meta.audio.value" />
        <DocumentItemsNextPrevious v-if="isLeafNode" :document-item-id="documentItem.documentitemid" />
        <Toolbar />
        <DocumentItemMenu :document-item="documentItem" />
      </div>
      <div class="pb-2">
        <TermAssigned 
          :data="documentItem"
          :allowed-term-types="isRenderComponent('allowedTermTypes')"
        />
      </div>
  </v-card>
</template>
  
<script>
import { mapState, mapGetters } from 'vuex'
import DocumentItemMenu from '@/components/documentItems/DocumentItemMenu'
import Breadcrumb from '@/components/documentItems/Breadcrumb'
import DocumentItemsNextPrevious from '@/components/documentItems/DocumentItemsNextPrevious'
import TermAssigned from '@/components/terms/TermAssigned'
import Toolbar from '@/components/common/Toolbar'
import Audio from '@/components/meta/Audio/Audio'
import configs from '@/configs'
import types from '@/helpers/types'

export default {
  name: 'DocumentItemHeading',
  components: {
    DocumentItemMenu,
    TermAssigned,
    DocumentItemsNextPrevious,
    Toolbar,
    Audio,
    Breadcrumb
  },
  props: {
    documentItem: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: true,
    connectionType: configs.connectionProperties.types
  }),
  computed: {
    ...mapState('documentItems', ['isLeafNode']),
    ...mapGetters('documents', ['renderComponent']),
    documentItemTypeName() {
      return types.getDocumentItemTypeNameById(this.documentItem.documentitemtypeid)
    },
    hasAudio() {
      return ('audio' in this.documentItem.meta && this.documentItem.meta.audio.value.length)
    }
  },
  methods: {
    isRenderComponent (componentName) {      
      return this.renderComponent(this.documentItem.documentitemtypeid, componentName)
    }
  }
}
</script>