<template>
  <div>
    <v-card :loading="loading" tile class="mx-auto">

      <template slot="progress">
        <v-progress-linear color="deep-purple" height="2" indeterminate></v-progress-linear>
      </template>

      <v-card-text v-if="phraseTranslations && phraseTranslations.length == 1" class="text-justify">
        <div v-html="phraseTranslations[0].explanation"></div>
        <p class="overline font-weight-thin" small>PHRASE</p>    
      </v-card-text>

      <div v-else>
        <v-tabs v-model="phraseTabs" centered>
          <v-tab v-for="pt in phraseTranslations" :key="pt.phrasetranslationid" class="font-weight-light pa-0">
            {{ languageNameById(pt.languageid) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="phraseTabs">
          <v-tab-item v-for="pt in phraseTranslations" :key="pt.phrasetranslationid">
            <v-card-text class="text-justify">
              <div v-html="pt.explanation"></div>
            </v-card-text>
            <p class="overline font-weight-thin" small>PHRASE</p>    
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
    
    <v-divider class="mx-2"></v-divider>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import types from '@/helpers/types'
import DocAdmin from '@/store/data'

export default {
  name: 'Phrase',
  components: {
  },
  props: {
    phraseId: {
      type: Number,
      default: null
    },
    languageId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    loading: true,
    phraseTranslations: null,
    phraseTabs: null
  }),
  computed: {
    ...mapGetters('languages', ['languageNameById'])
  },
  mounted() {
    this.getPhraseTranslations()
  },
  methods: {
    getPhraseTranslations () {
      DocAdmin.phraseTranslations.list({ params: { phraseid: this.phraseId, languageid: this.languageId } }).then((response) => {
        this.loading = false
        this.phraseTranslations = response.data.results
      }, (err) => {
        console.error(`getPhraseTranslations.error = ${err.message}`)
        this.loading = false
      })
    }
  }
}
</script>