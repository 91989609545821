<template>
  <v-row no-gutters>
    <v-col>
      <v-card flat tile v-if="dit && dit.length">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Title</th>
                <th class="text-left">Description</th>
                <th class="text-left">Body</th>
                <th class="text-left">Language</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="translation of dit" :key="translation.id">
                <td>{{ translation.title }}</td>
                <td>{{ translation.description ? String(translation.description).substring(0, 50) + '...' : '' }}</td>
                <td>{{ translation.body ? String(translation.body).substring(0, 150) + '...' : '' }}</td>
                <td>{{ languageNameById(translation.languageid) }}</td>
                <td>
                  <v-btn-toggle tile borderless dense>
                    <v-btn @click="add"><v-icon dense color="primary">mdi-plus-circle-outline</v-icon></v-btn>
                    <v-btn @click="edit(translation.id)"><v-icon dense color="orange">mdi-pencil</v-icon></v-btn>
                    <v-btn @click="remove(translation.id)"><v-icon dense color="red">mdi-delete</v-icon></v-btn>
                  </v-btn-toggle>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-card v-else class="text-center" tile flat>
        <v-card-text>
          <v-btn color="green" @click="add">Add Translation</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import ImageManager from '@/components/ImageManager'
import { mapState, mapGetters, mapActions } from 'vuex'
import configs from '@/configs'

export default {
  name: 'DocumentItemTranslationListing',
  components: {
    ImageManager
  },
  props: {},
  data() {
    return {
      connectionType: configs.connectionProperties.types
    }
  },
  computed: {
    ...mapGetters('documentItems', ['isEnableTranslationImage', 'getDocumentItemTranslations']),
    ...mapState('documentItems', ['documentItemTranslations']),
    ...mapGetters('languages', ['languageShortCodeById', 'languageNameById']),
    dit() {
      return this.getDocumentItemTranslations(this.$route.params.documentitemid)
    },
    columns() {
      if (this.documentItemTranslations.length >= 3) {
        return 4
      } else if (this.documentItemTranslations.length >= 2) {
        return 6
      }
      return 12
    }
  },
  async created (){
    if(!this.dit.length) {
      console.log(`DocumentItemTranslationListing.getDocumentItemTranslations documentitemid=${this.$route.params.documentitemid}`)
      await this.$store.dispatch('documentItems/getDocumentItemTranslations', this.$route.params.documentitemid)
    }
  },
  methods: {
    ...mapActions('documentItems', ['deleteDocumentItemTranslation']),
    add () {
      this.$router.push({ 
        name: 'documentitem_translations_add'
      })
    },
    edit (id) {
      this.$router.push({ 
        name: 'documentitem_translations_edit', 
        params: {
          documentitemtranslationid: id
        }
      })
    },
    async remove (id) {
      await this.deleteDocumentItemTranslation(id)
      this.$store.dispatch('app/setSnackBar', `Deleted translation id=${id}`)
    }
  }
}
</script>