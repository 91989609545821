<template>
  <div>
    <v-card :loading="loading" tile class="mx-auto">
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="2" indeterminate></v-progress-linear>
      </template>
      <ImageManager
        :id="personId"
        :connection-type-id="connectionType.persons"
      />

      <v-card-title v-if="person" class="font-weight-medium">
        <router-link :to="{ name: 'person_home', params: { personid: person.personid }}">
          {{ person.englishname }}
        </router-link>
      </v-card-title>

      <v-card-text v-if="personTranslations && personTranslations.length == 1" class="text-justify">
        <div v-html="personTranslations[0].body"></div>
        <p class="overline font-weight-thin" small>PERSON</p>    
      </v-card-text>

      <div v-if="personTranslations && personTranslations.length > 1">
        <v-tabs v-model="personTabs" centered>
          <v-tab v-for="pt in personTranslations" :key="pt.persontranslationid" class="font-weight-light pa-0">
            {{ languageNameById(pt.languageid) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="personTabs">
          <v-tab-item v-for="pt in personTranslations" :key="pt.persontranslationid">
            <v-card-text class="text-justify">
              <div v-html="pt.body"></div>
              <p class="overline font-weight-thin" small>PERSON</p>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <div v-else>
        <p class="font-weight-medium">No person translations found.</p>
      </div>
      
    </v-card>
    
    <v-divider class="mx-2"></v-divider>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import types from '@/helpers/types'
import DocAdmin from '@/store/data'
import ImageManager from '@/components/ImageManager'

export default {
  name: 'Person',
  components: {
    ImageManager
  },
  props: {
    personId: {
      type: Number,
      default: null
    },
    languageId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    loading: true,
    person: null,
    personTranslations: null,
    personTabs: null
  }),
  computed: {
    ...mapGetters('languages', ['languageNameById'])
  },
  mounted() {
    this.getPerson()
  },
  methods: {
    getPerson () {
      DocAdmin.persons.get(this.personId).then((response) => {
        this.person = response.data
        this.getPersonTranslation()
      }, (err) => {
        console.error(`getPerson.error = ${err.message}`)
        this.loading = false
      })
    },
    getPersonTranslation () {
      DocAdmin.personTranslations.list({ params: { personid: this.personId, languageid: this.languageId } }).then((response) => {
        this.loading = false
        this.personTranslations = response.data.results
      }, (err) => {
        console.error(`getPersonTranslation.error = ${err.message}`)
        this.loading = false
      })
    }
  }
}
</script>