<template>
  <div class="d-flex m-0 justify-content-end">
    <div class="d-flex">
      <v-icon 
        @click="$router.push({ name: 'documentitem', params: { documentitemid: previousId }})"
        :disabled="!previousId"
      >
        mdi-chevron-left
      </v-icon>
      <v-spacer></v-spacer>
      <v-icon
        @click="$router.push({ name: 'documentitem', params: { documentitemid: nextId }})"
        :disabled="!nextId"
      >
        mdi-chevron-right
      </v-icon>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import DocAdmin from '@/store/data'

export default {
  name: 'DocumentItemsNextPrevious',
  props: {
    documentItemId: {
      type: [ Number, String ],
      default: null
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    nextId: undefined,
    previousId: undefined,
    loading: true
  }),
  mounted() {
    this.getNextPrevious()
  },
  methods: {
    async getNextPrevious() {
      const d = await DocAdmin.documentItemNextPrevious.list({ 
        params : {
          documentitemid: this.documentItemId
        } 
      })

      if (d.data.error) {
        console.error(`DocumentItemsNextPrevious error = ${d.data.errorMessage}`)
        this.loading = false
        return
      }

      this.nextId = d.data.next
      this.previousId = d.data.previous
      this.loading = false
    }
  }
}
</script>