<template>
  <v-breadcrumbs v-if="documentItem" class="pa-0 ma-0" :items="breadcrumb()"/>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props: {
    documentItem: {
      type: Object,
      default: null
    }
  },
  methods: {
    breadcrumb() {
      if (!this.documentItem) { return [] }
      const crumbs = []
      let documentId;

      if (this.documentItem.documentid) {
        if (typeof this.documentItem.documentid === "object") {
          documentId = this.documentItem.documentid.documentid
        } else {
          documentId = this.documentItem.documentid
        }
      }
      
      JSON.parse(this.documentItem.breadcrumb).forEach((breadcrumbItem) => {
        const crumb = { 
          text: breadcrumbItem.title,
          exact: true
        }

        if (breadcrumbItem.type.toLowerCase() === 'documents') {
          crumb.to = `/app/document/${breadcrumbItem.id}/home`
        }
        
        if (breadcrumbItem.type.toLowerCase() === 'documentitems') {
          crumb.to = `/app/document/${documentId}/documentitem/${breadcrumbItem.id}`
        }

        crumbs.push(crumb)
      })

      return crumbs
    }
  }
}
</script>